import $ from 'jquery'
// import objectFitPolyfill from './object-fit-polyfill'
// import EventEmitter from 'eventemitter3'

let InstructionVideoController = function (options) {
  const _this = this
  let _settings

  /* state */
  let _player = null
  let _playerLoaded = false
  let _playerLoadedQueue = []

  /* public */
  _this.init = function () {
    _settings = $.extend(
      {
        element: null,
        youtubeId: null,
        youtubeFrameWorkManager: null
      },
      options
    )

    console.log('test')

    _bindUIActions()
  }

  // /* private */
  // function _makeYoutubeElement () {
  //   return $(
  //     '<iframe width="900" height="400" src="https://www.youtube.com/embed/' +
  //       _settings.youtubeId +
  //       '?rel=0&showinfo=0&modestbranding=0&color=white&autoplay=1&controls=1&loop=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>'
  //   )[0]
  // }

  /* private */
  _this.showVideo = function () {
    // $(_getVideoContainer()).html(_makeYoutubeElement())

    if (_player === null) {
      _withYT(YT => {
        _playerLoaded = false
        _player = new YT.Player(_getVideoContainer(), {
          width: 900,
          height: 400,
          videoId: _settings.youtubeId,
          playerVars: {
            autoplay: 1,
            controls: 1,
            modestbranding: 1,
            playsinline: 1
          }
        })

        _player.addEventListener('onReady', () => {
          _notifyPlayerLoaded()
        })
      })
    }

    $(_getContainer()).addClass('video-shown')
  }

  /* public */
  _this.seekTo = function (seconds) {
    _this.showVideo()
    _enqueueOnPlayerLoaded(() => {
      console.log(_player)
      _player.seekTo(seconds, true)
    })
  }

  /* private */
  _this.hideVideo = () => {
    $(_getContainer()).removeClass('video-shown')
    if (_player !== null) {
      _player.destroy()
      _player = null
      _playerLoaded = false
      _playerLoadedQueue = []
    }
  }

  /* private */
  function _bindUIActions () {
    $(_getShowButton()).click(() => {
      console.log('click')
      _this.showVideo()
      return false
    })
    $(_getHideButton()).click(() => {
      _this.hideVideo()
      return false
    })
  }

  /* private */
  function _withYT (func) {
    _settings.youtubeFrameWorkManager.executeWithYT(func)
  }

  /* private */
  function _getContainer () {
    return _settings.element
  }

  /* private */
  function _getShowButton () {
    return $(_getContainer()).find('.instruction-video-show-button')[0]
  }

  /* private */
  function _getHideButton () {
    return $(_getContainer()).find('.instruction-video-hide-button')[0]
  }

  /* private */
  function _getVideoContainer () {
    return $(_getContainer()).find('.instruction-video-video')[0]
  }

  /* private */
  function _notifyPlayerLoaded () {
    _playerLoaded = true
    _executeLoadedQueue()
  }

  /* private */
  function _executeLoadedQueue () {
    if (_playerLoaded) {
      while (_playerLoadedQueue.length > 0) {
        const func = _playerLoadedQueue.shift()
        func()
      }
    }
  }

  /* private */
  function _enqueueOnPlayerLoaded (func) {
    _playerLoadedQueue.push(func)
    _executeLoadedQueue()
  }
}

export default InstructionVideoController
